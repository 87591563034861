import { useTranslation } from 'react-i18next';
import Spinner from 'react-bootstrap/Spinner';
import Alert from 'react-bootstrap/Alert';

const AlertRenameFailed = () => {
    const { t } = useTranslation();

    return (
        <Alert key="error" variant="danger">
            {t('The last attempt to rename the file failed, and the original filename has been restored.')}
        </Alert>
    );
}

export default AlertRenameFailed;
