import React, { useState, useEffect, useContext, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { mdiSchool, mdiPlus, mdiPencil, mdiContentSave, mdiCancel } from '@mdi/js';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import Overlay from 'react-bootstrap/Overlay';
import Tooltip from 'react-bootstrap/Tooltip';
import Table from 'react-bootstrap/Table';

import { API_ENDPOINT_STUDIES } from '../../const.js';
import { retrieveData, saveData, deleteData, getDropdownData } from '../../data.js';
import { StateContext } from '../../App';
import { GenericDetailScreen, GenericDetailError } from '../Generic.js';
import Layout from '../Layout';
import Result from '../Result/Result';
import PageHeader from '../PageHeader';
import IconButtonSet from '../IconButtonSet';
import IconButton from '../IconButton';
import LoadingSpinner from '../LoadingSpinner.js';
import Published from '../Published';
import FormattedDate from '../FormattedDate';
import FormattedNumber from '../FormattedNumber';
 
export const Studies = () => {
    const { t } = useTranslation();
    const [selectedRowData, setSelectedRowData] = React.useState({});
    const [checkedIds, setCheckedIds] = useState(false);
    const [resetCheckboxes, setResetCheckboxes] = useState(false);
    const [refreshData, setRefreshData] = React.useState(false);
    const { locale, setAlert, setModalText, setShowModal, setShowModalConfirm, setModalConfirmData } = useContext(StateContext);

    const columns = [
        { id: 'id', type: 'checkbox', sort: false, filter: false },
        { id: 'name', route: `/${locale}/admin/studies/:id/view`, caption: t('Name'), width: '25%', class: 'width-50p-sm', filter: false },
        { id: 'published', type: 'boolean', width: '7em', caption: t('Published') },
        { id: 'chapter', key: 'name', caption: t('Chapter'), class: 'hide-lg', filter: false },
        { id: 'nr_files', type: 'number', sort: false, caption: t('Files'), align: 'right', width: '8em', class: 'hide-xl', filter: false },
        { id: 'date_modified', type: 'datetime', caption: t('Updated'), width: '8em', class: 'hide-xl', filter: false },
        { id: 'actions', type: 'actions', caption: t('Actions'),
            actions: [
                {type: 'view', title: t('View study'), route: `/${locale}/admin/studies/:id/view`},
                {type: 'edit', title: t('Edit study'), route: `/${locale}/admin/studies/:id/edit`},
                {type: 'delete', title: t('Delete study'), onClick: (event) => { deleteStudy(event) }}
            ],
            sort: false,
            filter: false
        },
    ];

    const controlItems = [
        'search',
        'filter',
        'sort',
        {
            type: 'button',
            mdiIcon: mdiPlus,
            caption: t('New Study'),
            variant: 'success',
            route: `/${locale}/admin/studies/new`,
        }
    ]

    const bulkActions = [
        { id: 'unpublish', caption: t('Unpublish'), onClick: () => unpublishMultipleStudies() },
        { id: 'delete', caption: t('Delete'), onClick: () => deleteMultipleStudies() },
    ];

    const setResultCheckboxes = (checkboxStatus) => {
        let arrayId = [];
        for (const [key, value] of Object.entries(checkboxStatus)) {
            if(value === true) {
                arrayId.push(key);
            }
        }
        setCheckedIds(arrayId);
    }

    const modalConfirmCallback = (type) => {
        if(type === 'delete') {
            deleteConfirmed();
        }
        else if(type === 'unpublish') {
            unpublishConfirmed();
        }
    }

    const unpublishMultipleStudies = () => {
        if(checkedIds.length > 0) {
            unpublishShowConfirmation({id: checkedIds});
        }
    }

    const unpublishShowConfirmation = (rowData) => {
        setSelectedRowData(rowData);

        let title = null;
        let text = null;
        if(!Array.isArray(rowData.id)) {
            return false;
        }

        title = t('Unpublish studies');
        if(rowData.id.length === 1) {
            text = t('Are you sure you want to unpublish 1 study?');
        }
        else {
            text = t('Are you sure you want to unpublish {{num}} studies?', { num: rowData.id.length });
        }

        setModalConfirmData({ title: title, text: text, buttonCaption: t('Unpublish'), type: 'unpublish' });
        setShowModalConfirm(true);
    }

    const unpublishConfirmed = () => {
        const arrayFileIds = selectedRowData.id;      // Contains an array of file ids
        if(!Array.isArray(arrayFileIds) || arrayFileIds.length === 0) {
            return;
        }

        saveData(API_ENDPOINT_STUDIES, null, { ids: arrayFileIds }, (data) => {
            setShowModalConfirm(false);
            setSelectedRowData({});

            if(data.success === true) {
                // Scroll to top
                window.scrollTo(0, 0);

                // Reset checkboxes in child component Result
                setResetCheckboxes(true);

                // Display alert
                const numberOfUnpublishedFiles = data?.data['204'] || 0;
                if(numberOfUnpublishedFiles === 1) {
                    setAlert({text: t('Operation completed: 1 study unpublished.')});
                }
                else {
                    setAlert({text: t('Operation completed: {{num}} studies unpublished.', { num: numberOfUnpublishedFiles })});
                }

                // Rerender result table
                setRefreshData(true);
            }
            else {
                setModalText(t('Could not unpublish the selected studies. The system returned the following error message: {{error}}', { error: data.error }));
                setShowModal(true);
            }
        }, 'PUT');
    }

    const deleteMultipleStudies = () => {
        if(checkedIds.length > 0) {
            deleteShowConfirmation({id: checkedIds});
        }
    }

    const deleteStudy = (event) => {
        const studyId = event.currentTarget.parentNode.getAttribute('data-rec-id');
        const studyName = event.currentTarget.parentNode.getAttribute('data-rec-name');
        if(studyId <= 0) {
            return;
        }

        const rowData = { id: studyId, name: studyName };
        deleteShowConfirmation(rowData);
    }

    const deleteShowConfirmation = (rowData) => {
        setSelectedRowData(rowData);

        let title = null;
        let text = null;
        if(Array.isArray(rowData.id)) {
            title = t('Delete studies');
            if(rowData.id.length === 1) {
                text = t('Are you sure you want to delete 1 study?');
            }
            else {
                text = t('Are you sure you want to delete {{num}} studies?', { num: rowData.id.length });
            }
        }
        else {
            title = t('Delete study');
            text = t('Are you sure you want to delete study {{name}} (#{{id}})?', rowData);    
        }

        setModalConfirmData({ title: title, text: text, buttonCaption: t('Delete'), type: 'delete' });
        setShowModalConfirm(true);
    }

    const deleteConfirmed = () => {
        const studyId = selectedRowData.id;
        const studyName = selectedRowData.name;
        if(studyId <= 0) {
            return;
        }

        deleteData(API_ENDPOINT_STUDIES, studyId, (data) => {
            setShowModalConfirm(false);
            setSelectedRowData({});

            if(data.success === true) {
                // Scroll to top
                window.scrollTo(0, 0);

                // Reset checkboxes in child component Result
                setResetCheckboxes(true);

                // Display alert
                if(Array.isArray(selectedRowData.id)) {
                    // Multiple items
                    const numberOfDeletedStudies = data?.data['204'] || 0;
                    const numberOfFailedStudies = data?.data['409'] || 0;
                    let textKey = null;
                    if(numberOfFailedStudies > 0) {
                        textKey = 'Operation completed: {{numDeleted}} study/studies deleted, {{numFailed}} study/studies could not be deleted as one or more files link to them.';
                    }
                    else if(numberOfDeletedStudies === 1) {
                        textKey = 'Operation completed: 1 study successfully deleted.';
                    }
                    else {
                        textKey = 'Operation completed: {{numDeleted}} study/studies successfully deleted.';
                    }
                    setAlert({ text: t(textKey, { numDeleted: numberOfDeletedStudies, numFailed: numberOfFailedStudies }) });
                }
                else {
                    // Single item
                    setAlert({text: t('Study {{name}} (#{{id}}) is successfully deleted.', selectedRowData)});
                }

                // Rerender result table
                setRefreshData(true);
            }
            else {
                let textKey = null;
                if(Array.isArray(selectedRowData.id)) {
                    textKey = 'Could not delete the selected study/studies. The system returned the following error message: {{error}}';
                }
                else {
                    if(data.status === 409) {
                        textKey = 'Could not delete study {{name}} (#{{id}}), because at least one file links to it.';
                    }
                    else {
                        textKey = 'Could not delete study {{name}} (#{{id}}). The system returned the following error message: {{error}}';
                    }
                }
                setModalText(t(textKey, { id: studyId, name: studyName, error: data.message }));
                setShowModal(true);
            }
        });
    }

    return (
        <Layout modalConfirmCallback={modalConfirmCallback}>
            <PageHeader mdiIcon={mdiSchool} path={[{caption: t('Admin Panel'), route: `/${locale}/admin`}]}>{t('Studies')}</PageHeader>
            <Result
                endpoint={API_ENDPOINT_STUDIES}
                unpublished={true}
                columns={columns}
                controlItems={controlItems}
                bulkActions={bulkActions}
                setResultCheckboxes={setResultCheckboxes}
                refreshData={refreshData}
                setRefreshData={setRefreshData}
                resetCheckboxes={resetCheckboxes}
                setResetCheckboxes={setResetCheckboxes}
            />
        </Layout>
    )
}

const getStudy = (studyId, setter, callback) => {
    studyId = parseInt(studyId);
    if(studyId > 0) {
        const url = API_ENDPOINT_STUDIES + studyId + '/';
        retrieveData(url, { 'also_unpublished': 'true' }, (data) => {
            setter(data);
            if(typeof(callback) === 'function') {
                callback(data);
            }
        });
    }
    else {
        // Don't bother trying to retrieve a record for an ID <= 0, immediately return 404
        setter({result: null, success: false, status: 404});
    }
}

export const StudiesEdit = (props) => {
    const { studyId } = useParams();
    const action = props.action;
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { locale, setModalText, setShowModal } = useContext(StateContext);

    const [formIsDisabled, setFormIsDisabled] = useState(studyId > 0 ? true : false);
    const [formIsSaving, setFormIsSaving] = useState(false);
    const [data, setData] = React.useState(null);
    const [dropdownData, setDropdownData] = React.useState({});
    
    const [showTooltipName, setShowTooltipName] = useState(false);
    const [showTooltipChapter, setShowTooltipChapter] = useState(false);

    const [studyName, setStudyName] = React.useState('');
    const [studyChapter, setStudyChapter] = React.useState('');
    const [studyPublished, setStudyPublished] = React.useState(false);

    const targetName = useRef(null);
    const targetChapter = useRef(null);
    const targetNameLabel = useRef(null);
    const targetChapterLabel = useRef(null);

    const screenData = {
        title: action === 'edit' && studyId > 0 ? t('Edit study') : t('New study'),
        subTitle: studyId > 0 ? t('Study (#{{id}})', { id: studyId}) : t('Study'),
        mdiIcon: mdiSchool,
        path: [
            {caption: t('Studies'), route: `/${locale}/admin/studies`},
        ],
    }

    const submitForm = (event, studyId) => {
        event.preventDefault();

        // Validation
        if(studyName.length === 0) {
            // Name is required
            setShowTooltipName(true);
            targetName.current.focus();
            return false;
        }

        if(!studyChapter) {
            // Chapter is required
            setShowTooltipChapter(true);
            targetChapter.current.focus();
            return false;
        }
    
        // Disable form and show spinner
        setFormIsDisabled(true);
        setFormIsSaving(true);
    
        // Setup payload
        const payload = {
            name: studyName.trim(),
            chapter: parseInt(studyChapter),
            published: !!studyPublished,
        };

        // Send data
        saveData(API_ENDPOINT_STUDIES, studyId, payload, (response) => {
            // Enable form and hide spinner
            setFormIsDisabled(false);
            setFormIsSaving(false);

            if(response.success === true) {
                // Success
                if(!studyId) {
                    studyId = response.data.id;
                }

                // Navigate to detail view
                navigate(`/${locale}/admin/studies/${studyId}/view`);
            }
            else {
                // Failure
                setModalText(response?.error?.message ?? 'Error');
                setShowModal(true);
            }    
        });
    }

    const handleChangeStudyName = (event) => {
        setStudyName(event.target.value);
        setShowTooltipName(false);
    }

    const handleChangeStudyChapter = (event) => {
        setStudyChapter(event.target.value);
        setShowTooltipChapter(false);
    }

    const handleChangeStudyPublished = (event) => {
        setStudyPublished(!!event.target.checked);
    }

    useEffect(() => {
        if(action === 'edit') {
            getStudy(studyId, setData, (data) => {
                // Set form values
                if(data.result) {
                    setStudyName(data.result?.name);
                    setStudyChapter(data.result?.chapter?.id);
                    setStudyPublished(!!data.result?.published);
                }
                // Enable form 
                setFormIsDisabled(false);
            });
        }
        else {
            setData({ success: true });
        }
    }, [action, studyId]);

    useEffect(() => {
        getDropdownData(['chapter'], setDropdownData, false);
    }, []);

    if(data === null) {
        return(
            <GenericDetailScreen data={screenData}>
                <LoadingSpinner />
            </GenericDetailScreen>
        );
    }

    if(data.success === true) {
        return(
            <GenericDetailScreen data={screenData}>
                <Form onSubmit={event => submitForm(event, studyId)} name="studyForm">
                    <fieldset disabled={formIsDisabled}>
                        <FloatingLabel controlId="studyName" ref={targetNameLabel} label={t('Name')} className="mb-4">
                            <Form.Control
                                ref={targetName}
                                type="text"
                                maxLength="100"
                                value={studyName}
                                placeholder={t('Study name')}
                                onChange={handleChangeStudyName}
                                onBlur={() => { setShowTooltipName(false) }}
                                autoFocus
                            />
                        </FloatingLabel>
                        <Overlay target={targetNameLabel.current} show={showTooltipName} placement="bottom">
                            <Tooltip className="formValidation">
                                {t('Please enter a study name.')}
                            </Tooltip>
                        </Overlay>

                        <FloatingLabel controlId="studyChapter" ref={targetChapterLabel} label={t('Chapter')} className="mb-4">
                            <Form.Select
                                ref={targetChapter}
                                onChange={handleChangeStudyChapter}
                                onBlur={() => { setShowTooltipChapter(false) }}
                                value={studyChapter}
                            >
                                {
                                    dropdownData?.chapter &&
                                        <>
                                            <option value="">{t('(None)')}</option>
                                            {
                                                dropdownData.chapter.map((rec, k) =>
                                                    <option value={rec.id} key={k}>{rec.name}</option>
                                                )
                                            }
                                        </>
                                }
                            </Form.Select>
                        </FloatingLabel>
                        <Overlay target={targetChapterLabel.current} show={showTooltipChapter} placement="bottom">
                            <Tooltip className="formValidation">
                                {t('Please select a chapter.')}
                            </Tooltip>
                        </Overlay>
                        
                        <div className="mb-4">
                            <Form.Label>{t('Publication status')}</Form.Label>
                            <Form.Switch 
                                defaultChecked={studyPublished}
                                onChange={handleChangeStudyPublished}
                                id="studyPublished"
                                label={t('Study is published to website')}
                            />
                        </div>

                        <hr />

                        <IconButtonSet showSpinner={formIsSaving}>
                            <IconButton mdiIcon={mdiContentSave} onClick={(event) => submitForm(event, studyId)}>{t('Save')}</IconButton>
                            <IconButton mdiIcon={mdiCancel} variant="outline-primary" route={`/${locale}/admin/studies`}>{t('Cancel')}</IconButton>
                        </IconButtonSet>
                    </fieldset>
                </Form>
            </GenericDetailScreen>
        );
    }

    return (
        <GenericDetailScreen data={screenData}>
            <StudiesDetailError data={data} />
        </GenericDetailScreen>
    )
}

export const StudiesView = () => {
    const { studyId } = useParams();
    const { t } = useTranslation();
    const [data, setData] = React.useState(null);
    const { locale } = useContext(StateContext);

    const screenData = {
        title: t('View study'),
        subTitle: studyId > 0 ? t('Study (#{{id}})', { id: studyId}) : t('Study'),
        mdiIcon: mdiSchool,
        path: [
            {caption: t('Studies'), route: `/${locale}/admin/studies`},
        ],
        backButton: true,
    }
    
    useEffect(() => {
        getStudy(studyId, setData);
    }, [studyId]);

    if(data === null) {
        return(
            <GenericDetailScreen data={screenData}>
                <LoadingSpinner />
            </GenericDetailScreen>
        );
    }

    if(data.success === true) {
        return(
            <GenericDetailScreen data={screenData}>
                <Table bordered striped responsive className="detailTable mb-4">
                    <tbody>
                        <tr>
                            <th>{t('ID')}</th>
                            <td>#{data.result?.id}</td>
                        </tr>
                        <tr>
                            <th>{t('Name')}</th>
                            <td>{data.result?.name}</td>
                        </tr>
                        <tr>
                            <th>{t('Chapter')}</th>
                            <td>{data.result?.chapter?.name}</td>
                        </tr>
                        <tr>
                            <th>{t('Number of files')}</th>
                            <td><FormattedNumber>{data.result?.nr_files}</FormattedNumber></td>
                        </tr>
                        <tr>
                            <th>{t('Published')}</th>
                            <td><Published value={data.result?.published} /></td>
                        </tr>
                        <tr>
                            <th>{t('Created')}</th>
                            <td><FormattedDate format="full">{data.result?.date_created}</FormattedDate></td>
                        </tr>
                        <tr>
                            <th>{t('Updated')}</th>
                            <td><FormattedDate format="full">{data.result?.date_modified}</FormattedDate></td>
                        </tr>
                    </tbody>
                </Table>

                <IconButtonSet>
                    <IconButton mdiIcon={mdiPencil} route={`/${locale}/admin/studies/${studyId}/edit`}>{t('Edit study')}</IconButton>
                </IconButtonSet>
            </GenericDetailScreen>
        );
    }

    return (
        <GenericDetailScreen data={screenData}>
            <StudiesDetailError data={data} />
        </GenericDetailScreen>
    )
}

const StudiesDetailError = (props) => {
    const { t } = useTranslation();
    const { locale } = useContext(StateContext);

    return (
        <GenericDetailError
            data={props.data}
            messageNotFound={t('This study does not exist (anymore).')}
            buttons={
                [
                    { mdiIcon: mdiPencil, route: `/${locale}/admin/studies/new`, caption: t('New study') },
                ]
            }
        />
    )
}
